import { baseURL } from '@/api/requests/httpAxios'
import { imagePreview } from '@/config/default/defaultImages'
import ContentFactory from '@/factories/content'
import _ from 'lodash'

export class OrganizationAdapter {
  private static organizationAdapter(organization: any) {
    const emptyOrganization = ContentFactory.emptyOrganization()
    const avatarUrl = organization.avatarUrl
      ? baseURL + organization.avatarUrl
      : imagePreview
    return {
      id: organization.id ?? emptyOrganization.id,
      type: emptyOrganization.type,
      organizationType: organization.type ?? emptyOrganization.organizationType,
      title: organization.title ?? emptyOrganization.title,
      address: organization.address ?? emptyOrganization.address,
      avatarUrl: avatarUrl,
      shortDescription:
        organization.shortDescription ?? emptyOrganization.shortDescription,
      fullDescription:
        organization.fullDescription ?? emptyOrganization.fullDescription
    }
  }

  public static getOrganizations(organizations: any[]) {
    return organizations.map(org => this.organizationAdapter(org))
  }

  public static adaptOrganization(content: any) {
    const organizationContentLinks = content.organizationContentLinks
    if (organizationContentLinks && organizationContentLinks.length > 0) {
      content.organizations = organizationContentLinks.map(
        (e: any) => e.organization
      )
      delete content['organizationContentLinks']
    } else {
      content.organizations = ContentFactory.contentManagerEventsObject().organizations
    }

    return content
  }

  public static async formatOrganizationsData(organization: any) {
    const formData = new FormData()

    if (
      ContentFactory.emptyOrganization().avatarUrl === organization.avatarUrl
    ) {
      organization.avatarUrl = null
    }

    if (!_.isNil(organization.avatarUrl)) {
      const avatarBlob = await (await fetch(organization.avatarUrl)).blob()
      const avatarFile = new File([avatarBlob], 'avatar.png', {
        type: 'image/png'
      })
      formData.append('avatar', avatarFile)
    }
    formData.append('title', organization.title)
    formData.append('type', organization.organizationType)
    if (!_.isNil(organization.address)) {
      formData.append('address', organization.address)
    }
    if (!_.isNil(organization.shortDescription)) {
      formData.append('shortDescription', organization.shortDescription)
    }
    if (!_.isNil(organization.fullDescription)) {
      formData.append('fullDescription', organization.fullDescription)
    }
    return formData
  }
}
