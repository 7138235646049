export enum typeOrganization {
  'Школа' = 'Школа', // Школа
  'СПО' = 'СПО', // СПО
  'Другое' = 'Другое' // Другое
}

export const organizationBadges: { [k: string]: string } = {
  [typeOrganization.Школа]: 'success',
  [typeOrganization.СПО]: 'warning',
  [typeOrganization.Другое]: 'primary'
}
