import { Getters, Mutations, Module, createMapper } from 'vuex-smart-module'
import Config from '@/config/default/config'

class NavBarTitleState {
  title = Config.systemTitle.fullName
  subTitle = ''
}

class NavBarTitleGetters extends Getters<NavBarTitleState> {
  get navBarTitle() {
    return this.state.title
  }
  get navBarSubTitle() {
    return this.state.subTitle
  }
}

class NavBarTitleMutations extends Mutations<NavBarTitleState> {
  setTitleFromRoute(info: any) {
    const firstPart = info.to.meta.group
    const secondPart = info.to.meta.displayName

    // Is Auth Page
    this.state.title = firstPart
    this.state.subTitle = secondPart
  }

  setTitle(text: string) {
    this.state.title = text
  }
  setSubTitle(text: string) {
    this.state.subTitle = text
  }
}

export const navBarTitle = new Module({
  state: NavBarTitleState,
  getters: NavBarTitleGetters,
  mutations: NavBarTitleMutations
})

export const navBarTitleMapper = createMapper(navBarTitle)
