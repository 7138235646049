import OrganizationsAPI from '@/api/requests/organizations'
import { OrganizationAdapter } from '@/api/adapters/organizations'
import {
  Actions,
  createMapper,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module'
import _ from 'lodash'

class DictState {
  organizations: any[] = []
  organizationId: number | null = null
}

class DictGetters extends Getters<DictState> {
  get organizations(): any[] {
    return this.state.organizations
  }
  get organizationId(): number | null {
    return this.state.organizationId
  }
}

class DictMutations extends Mutations<DictState> {
  setOrganizations(organizations: any[]) {
    this.state.organizations = organizations
  }
  setOrganizationId(id: number | null) {
    this.state.organizationId = id
  }
}

class DictActions extends Actions<
  DictState,
  DictGetters,
  DictMutations,
  DictActions
> {
  async fetchAllOrganizations() {
    const response = await OrganizationsAPI.getOrganizations()
    try {
      if (!_.isEmpty(response)) {
        const organizations = OrganizationAdapter.getOrganizations(
          response.data
        )
        this.mutations.setOrganizations(organizations)
      }
    } catch (err) {
      console.error(err)
    }
  }

  changeOrganizationId(id: number | null) {
    this.mutations.setOrganizationId(id)
  }
}

export const organizations = new Module({
  state: DictState,
  getters: DictGetters,
  mutations: DictMutations,
  actions: DictActions
})

export const dictMapper = createMapper(organizations)
