import { http } from '@/api/requests/httpAxios'

import {
  LIMIT_PAGINATION_BELL,
  notificationTypes
} from '@/config/types/notificationTypes'
import { AxiosPromise } from 'axios'

export default class NotificationsAPI {
  public static getUserNotifications(
    limit: number = LIMIT_PAGINATION_BELL
  ): AxiosPromise {
    return http.get(`/notifications/latest?limit=${limit}`)
  }

  public static getNotificationWithPagination(
    limit: number,
    offset: number,
    type?: notificationTypes
  ): AxiosPromise {
    const params = new URLSearchParams()
    params.append('limit', limit.toString())
    params.append('offset', offset.toString())
    if (type) {
      params.append('type', type.toString())
    }
    return http.get(`/notifications?${params.toString()}`)
  }

  public static markNotificationAsRead(notificationId: number): AxiosPromise {
    return http.put(`/notifications/${notificationId}/status?isRead=true`)
  }

  public static markNotificationAsUnRead(notificationId: number): AxiosPromise {
    return http.put(`/notifications/${notificationId}/status?isRead=false`)
  }
  public static markNotificationAsReadByType(
    type: notificationTypes
  ): AxiosPromise {
    return http.put(`/notifications/read?type=${type}`)
  }
  public static markNotificationAsReadAll(): AxiosPromise {
    return http.put('/notifications/read')
  }

  public static editNotifications(data: any): AxiosPromise {
    return http.put('/users/notifications/select', data)
  }
  public static vkNotifications(vkId: number | null): AxiosPromise {
    return http.put('/users/notifications/vk', { vkId })
  }
  public static emailNotifications(data: boolean): AxiosPromise {
    return http.put('/users/notifications/email', { data })
  }
}
