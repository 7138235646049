









import { Vue, Component } from 'vue-property-decorator'
import Navbar from '@/components/ui/Navbar.vue'
import Sidebar from '@/components/ui/Sidebar.vue'
import Toaster from '@/components/ui/Toaster.vue'
import { userMapper } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated'])
  }
})
@Component({
  components: {
    Navbar,
    Sidebar,
    Toaster
  }
})
export default class Home extends Mappers {
  onReload() {
    location.reload(true)
  }
}
