import { defineAbility } from '@casl/ability'
import store from '@/store'

export enum permissions {
  viewPage = 'viewPage', // просмотр страницы
  inviteStudents = 'inviteStudents', // кнопка приглашения учеников
  goToStudents = 'goToStudents', // кнопка перехода к ученикам
  goToOrganization = 'goToOrganization', // кнопка перехода к организации
  editOrganization = 'editOrganization', // кнопка редактирования для организаций
  deleteOrganization = 'deleteOrganization', // кнопка удаления организации
  inviteOrganization = 'inviteOrganization', // кнопка добавления организации
  editNewsEvents = 'editNewsEvents', // кнопка редактирования для новостей/событий
  viewContentManagerButtons = 'viewContentManagerButtons', // просмотр кнопок добавления, публикации, бэйдж статуса
  viewContentStatus = 'viewContentStatus', // просмотр бэйджа со статусом на карточке превью
  viewDislikesCount = 'viewDislikesCount', // просмотр счетчика дизлайков
  viewProfile = 'viewProfile', // кнопка для просмотра профиля КР, админом
  viewNotificationTabs = 'viewNotificationTabs', // табы для уведомлений
  changeListOfParticipants = 'changeListOfParticipants', // изменять список участников события
  seeViewsLikes = 'seeViewsLikes', // видеть лайки и просмотры
  viewMyEventsButton = 'viewMyEventsButton', // кнопка для событий в рамках организации для КР
  seeParticipants = 'seeParticipants', // видеть участников события

  addProject = 'addProject'
}

export class PermissionManager {
  // Права на просмотр страниц (Router, SideBar)
  public static pages() {
    return defineAbility(can => {
      if (this.user('isAuthenticated')) {
        if (this.user('isAdmin')) {
          can(permissions.viewPage, 'AdminHistory')
          can(permissions.viewPage, 'AdminListOfUsers')
          can(permissions.viewPage, 'AboutProjectEditForm')
          can(permissions.viewPage, 'UserProfile')
          can(permissions.viewPage, 'PortfolioStudent')
          can(permissions.viewPage, 'ServerLogs')
          can(permissions.viewPage, 'Sessions')
          can(permissions.viewPage, 'Activity')
          can(permissions.viewPage, 'BuildInfo')
          can(permissions.viewPage, 'StudentsList')
          can(permissions.viewPage, 'StudentsETUList.vue')
          can(permissions.viewPage, 'ProjectAddRoute')
          can(permissions.viewPage, 'FakeAuthRoute')
        }
        if (this.user('isDeveloper')) {
          can(permissions.viewPage, 'DevelopmentMoodlePage')
          can(permissions.viewPage, 'ProjectAddRoute')
        }
        if (this.user('isTeacher')) {
          can(permissions.viewPage, 'PortfolioStudent')
          can(permissions.viewPage, 'StudentsList')
          can(permissions.viewPage, 'UserProfile')
          can(permissions.viewPage, 'ProjectAddRoute')
        }
        if (this.user('isCurator')) {
          can(permissions.viewPage, 'UserProfile')
          can(permissions.viewPage, 'PortfolioStudent')
          can(permissions.viewPage, 'StudentsList')
          can(permissions.viewPage, 'StudentsETUList.vue')
          can(permissions.viewPage, 'ProjectAddRoute')
        }
        if (this.user('isContentManager')) {
          can(permissions.viewPage, 'ProjectAddRoute')
        }
        if (this.user('isStudent')) {
          can(permissions.viewPage, 'PortfolioStudent')
        }
        // Для всех авторизованных тут
        can(permissions.viewPage, 'Profile')
        can(permissions.viewPage, 'EmailConfirmation')
        can(permissions.viewPage, 'Notifications')
        can(permissions.viewPage, 'Moodle')
        can(permissions.viewPage, 'Help')
        can(permissions.viewPage, 'Feed')
        can(permissions.viewPage, 'ProjectsListRoute')
        can(permissions.viewPage, 'ProjectPageRoute')

        // Для отладки
        if (process.env.NODE_ENV !== 'production') {
          can(permissions.viewPage, 'DevelopmentPage')
          can(permissions.viewPage, 'ChangeRole')
        }
      }

      if (!this.user('isAuthenticated')) {
        can(permissions.viewPage, 'PasswordRecovery')
        can(permissions.viewPage, 'Registration')
        can(permissions.viewPage, 'AuthUser')
        can(permissions.viewPage, 'Auth')
      }

      // Для всех пользователей
      can(permissions.viewPage, 'MainPage')
      can(permissions.viewPage, 'ListOrganizationsView')
      can(permissions.viewPage, 'OrganizationViewer')
      can(permissions.viewPage, 'ErrorPage')
      can(permissions.viewPage, 'ErrorAuthLkPage')
      can(permissions.viewPage, 'NewsViewer')
      can(permissions.viewPage, 'EventsViewer')
      can(permissions.viewPage, 'AllNews')
      can(permissions.viewPage, 'AllEvents')
    })
  }

  public static notifications() {
    return defineAbility(can => {
      const notificationTypes = this.user('notificationTypes')
      for (const tab of notificationTypes) {
        can(permissions.viewNotificationTabs, tab.type)
      }
    })
  }

  // Права на дополнительные кнопки
  public static common() {
    return defineAbility(can => {
      if (this.user('isAdmin')) {
        can(permissions.inviteStudents, undefined)
        can(permissions.deleteOrganization, undefined)
        can(permissions.inviteOrganization, undefined)
        can(permissions.goToStudents, undefined)
        can(permissions.editOrganization, undefined)
        can(permissions.viewProfile, undefined)

        can(permissions.addProject, undefined)
      }
      if (this.user('isTeacher')) {
        can(permissions.goToStudents, undefined)
        can(permissions.inviteStudents, undefined)
        can(permissions.viewMyEventsButton, undefined)
        can(permissions.viewProfile, undefined)
        can(permissions.changeListOfParticipants, undefined)

        can(permissions.addProject, undefined)
      }
      if (this.user('isCurator')) {
        can(permissions.inviteStudents, undefined)
        can(permissions.goToStudents, undefined)
        can(permissions.viewProfile, undefined)
        can(permissions.inviteStudents, undefined)
        can(permissions.viewProfile, undefined)

        can(permissions.addProject, undefined)
      }
      if (this.user('isContentManager')) {
        can(permissions.viewContentManagerButtons, undefined)
        can(permissions.viewContentStatus, undefined)
        can(permissions.viewDislikesCount, undefined)
        can(permissions.editNewsEvents, undefined)

        can(permissions.addProject, undefined)
      }
      if (!this.user('isAuthenticated')) {
        can(permissions.seeViewsLikes, undefined)
        can(permissions.seeParticipants, undefined)
      }
      can(permissions.goToOrganization, undefined)
    })
  }

  static user(getterName: string) {
    return store.getters[`user/${getterName}`]
  }
}
