import { Getters, Mutations, Module, createMapper } from 'vuex-smart-module'

class LoadingState {
  isLoading = true
  enqueued = 0
  processed = 0
  errors = 0

  textSpinner = ''
}

class LoadingGetters extends Getters<LoadingState> {
  get isLoading() {
    return this.state.isLoading
  }

  get textSpinner() {
    return this.state.textSpinner
  }

  public get showProgress() {
    return this.state.enqueued !== this.state.processed
  }
}

class LoadingMutations extends Mutations<LoadingState> {
  disableLoading() {
    this.state.isLoading = false
  }

  enableLoading() {
    this.state.isLoading = true
  }

  setLoading(state: boolean) {
    this.state.isLoading = state
  }

  enqueue() {
    this.state.enqueued += 1
  }

  dequeue(ok = true) {
    this.state.processed += 1
    if (!ok) {
      this.state.errors += 1
    }
    if (this.state.enqueued <= this.state.processed) {
      this.state.enqueued = this.state.processed = this.state.errors = 0
    }
  }

  setTextSpinner(text: string) {
    if (text) {
      this.state.textSpinner = text + '...'
    } else {
      this.state.textSpinner = ''
    }
  }
}

export const loading = new Module({
  state: LoadingState,
  getters: LoadingGetters,
  mutations: LoadingMutations
})

export const loadingMapper = createMapper(loading)
