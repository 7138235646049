import _ from 'lodash'
import { User } from '@/types/interfaces/user'

export function organizationFormatter(params: any) {
  return (params.data?.organizations ?? [])
    .map((org: { title: any }) => org.title)
    .join(', ')
}

export function getUserText(user: User | null) {
  if (_.isNil(user)) {
    return ''
  }
  let str = user.initials
  if (!str) {
    str = `${user.surname} ${user.name[0]}.${
      user.midname ? user.midname[0] + '.' : ''
    }`
  }

  if (user.actualUser) {
    str += ` [${getUserText(user.actualUser)}]`
  }
  return str
}

export function shorten(text: string, maxLength: number) {
  let ret = text
  if (ret.length > maxLength) {
    ret = ret.substr(0, maxLength) + '...'
  }
  return ret
}
