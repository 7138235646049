import { http } from '@/api/requests/httpAxios'
import { OrganizationAdapter } from '@/api/adapters/organizations'
import { AxiosPromise } from 'axios'

export default class OrganizationsAPI {
  public static getOrganizations(): AxiosPromise {
    return http.get('/organizations')
  }
  public static async addOrganization(data: any) {
    return http.post(
      '/organizations',
      await OrganizationAdapter.formatOrganizationsData(data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
  public static deleteOrganization(id: number): AxiosPromise {
    return http.delete(`/organizations/${id}`)
  }
  public static async editOrganization(id: number, data: any) {
    return http.put(
      `/organizations/${id}`,
      await OrganizationAdapter.formatOrganizationsData(data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  public static getUsersFromOrganizations(): AxiosPromise {
    return http.get('/organizations/users')
  }
}
