








import { Vue, Component } from 'vue-property-decorator'
import MobileWidthMixin from '@/mixins/mobile'
import { loggerMapper } from '@/store/modules/logger'
import { userMapper } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...loggerMapper.mapState(['logs']),
    ...userMapper.mapState(['user']),
    ...userMapper.mapGetters(['isAuthenticated'])
  },
  methods: {
    ...loggerMapper.mapMutations(['addLog']),
    ...loggerMapper.mapActions(['sendLogs'])
  }
})

@Component({ mixins: [MobileWidthMixin] })
export default class App extends Mappers {
  private interval?: any
  private isUserExist = false

  private created() {
    window.onbeforeunload = null
    this.isUserExist = this.user && this.isAuthenticated

    if (this.isUserExist) {
      window.onbeforeunload = () => {
        this.sendLogs()
      }
      this.interval = setInterval(() => {
        this.sendLogs()
      }, 30000)
    }
    window.onunhandledrejection = (e: PromiseRejectionEvent) => {
      if (this.isUserExist) {
        this.addLog({
          userId: this.user.id,
          severity: 'error',
          message: 'ERROR',
          page: this.$route.path,
          error: {
            type: e.type,
            message: e.reason.message,
            stack: e.reason.stack
          },
          timestamp: Date.now()
        })
        this.sendLogs()
      }
    }
    if (this.isUserExist) {
      this.addLog({
        userId: this.user.id,
        severity: 'info',
        message: 'LOG IN',
        page: this.$route.path,
        timestamp: Date.now()
      })
      this.sendLogs()
    }
  }
}
