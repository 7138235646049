import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import { VueFormGeneratorFields } from '@orpk/orpk-vue-ui'

/* Bootstrap */
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

/* Fonts Awesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom } from '@fortawesome/fontawesome-svg-core'

library.add(fas, far)
dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)

/* Custom Field */
import AgGridField from '@/components/generatorFields/AgGrid.vue'
import BootstrapField from '@/components/generatorFields/Bootstrap.vue'
import DatePicker from '@/components/generatorFields/Datepicker.vue'
import DatetimePickerField from '@/components/generatorFields/DatetimePicker.vue'
import BootstrapFile from '@/components/generatorFields/BootstrapFile.vue'
import BootstrapMasked from '@/components/generatorFields/BootstrapMasked.vue'
import UserPicker from '@/components/generatorFields/UserPicker.vue'

Vue.component('fieldAgGrid', AgGridField)
Vue.component('fieldBootstrap', BootstrapField)
Vue.component('fieldBootstrapFile', BootstrapFile)
Vue.component(
  'fieldBootstrapCheckboxGroup',
  VueFormGeneratorFields.BootstrapCheckboxGroup
)
Vue.component(
  'fieldBootstrapCheckbox',
  VueFormGeneratorFields.BootstrapCheckbox
)
Vue.component(
  'fieldBootstrapDatepicker',
  VueFormGeneratorFields.BootstrapDatepicker
)
Vue.component(
  'fieldBootstrapRadioGroup',
  VueFormGeneratorFields.BootstrapRadioGroup
)
Vue.component('fieldBootstrapSelect', VueFormGeneratorFields.BootstrapSelect)
Vue.component(
  'fieldBootstrapTextArea',
  VueFormGeneratorFields.BootstrapTextArea
)
Vue.component('fieldCKEditor', VueFormGeneratorFields.CKEditor5Field)
Vue.component('fieldDatepicker', DatePicker)
Vue.component('fieldDatetimePicker', DatetimePickerField)

Vue.component('fieldHeaderSeparator', VueFormGeneratorFields.HeaderSeparator)
Vue.component('fieldUserPicker', UserPicker)

Vue.component('fieldMultiselect', VueFormGeneratorFields.MultiselectField)
Vue.component('fieldBootstrapMasked', BootstrapMasked)

/* Form Generator */
import VueFormGenerator from 'vue-form-generator'
Vue.use(VueFormGenerator)

/* Notifications bell */
import NotificationBell from 'vue-notification-bell'
Vue.use(NotificationBell)

/* CKEditor5 */
import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

/* DatetimePicker */
import { Datetime } from 'vue-datetime'
Vue.component('datetime', Datetime)
import { Settings } from 'luxon'
Settings.defaultLocale = 'ru'

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.silent = true
}

store.dispatch('user/fetchCurrentUser', true).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
