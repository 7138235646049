












import { GridApi, ICellRendererParams, IDatasource } from 'ag-grid-community'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import AgGridFactory from '@/factories/agGridFactory'
import RoleRenderer from '@/components/table/RoleRenderer'
import { rolesTranslation } from '@/config/types/roles'
import SetFilter from '@/components/table/filters/SetFilter'
import _ from 'lodash'
import moment from 'moment'
import dateFormat from '@/config/default/dateFormat'
import { moodleTranslation } from '@/config/types/moodleRights'
import CustomFilter from '@/components/table/filters/CustomFilter'
import { User } from '@/types/interfaces/user'

@Component({
  components: {
    AgGridVue,
    ActionRenderer
  }
})
export default class UserTable extends Vue {
  @Prop(Object) readonly dataSource!: IDatasource
  @Prop(Array) readonly rowData: User[] | undefined
  @Prop({ type: Boolean, default: true }) readonly isDisplayDateBirth!: boolean
  @Prop({ type: Boolean, default: true }) readonly isDisplayPhone!: boolean
  @Prop({ type: Boolean, default: true }) readonly isDisplayRole!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayClass!: boolean
  @Prop({ type: Boolean, default: true })
  readonly isDisplayOrganisation!: boolean
  @Prop({ type: Boolean, default: true })
  readonly isDisplayMoodleRights!: boolean
  @Prop({
    type: Array,
    default: () => []
  })
  readonly actions!: string[]
  @Prop({ type: Object, default: () => ({ height: '80vh' }) })
  readonly styleWrapper!: object

  private gridApi?: GridApi

  private defaultDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      width: 160,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (_.isNil(params.value)) {
          params.value = 'Данные отсутствуют'
        }
        return params.value
      }
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      width: 160,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (_.isNil(params.value)) {
          params.value = 'Данные отсутствуют'
        }
        return params.value
      }
    },

    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      width: 160,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (_.isNil(params.value)) {
          if (!_.isNil(params.data?.surname)) {
            params.value = ''
          } else {
            params.value = 'Данные отсутствуют'
          }
        }
        return params.value
      }
    },
    {
      headerName: 'Класс',
      field: 'class',
      colId: 'class',
      filter: 'agNumberColumnFilter',
      hide: !this.isDisplayClass,
      suppressSizeToFit: false,
      maxWidth: 90,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Почта',
      field: 'email',
      colId: 'email',
      width: 160,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Организация',
      field: 'organizations',
      colId: 'organizations',
      width: 160,
      // tooltipField: 'organizations', // TODO отображение
      hide: !this.isDisplayOrganisation,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (params.value) {
          params.value = params.value.map((r: any) => r.title)
        }
        return params.value ? params.value.join(', ') : null
      }
    },
    {
      headerName: 'Права Moodle',
      field: 'moodleRights',
      colId: 'moodleRights',
      width: 100,
      hide: !this.isDisplayMoodleRights,
      filter: 'agNumberColumnFilter',
      filterParams: CustomFilter.getFilter({
        false: 'отсутствуют',
        true: 'присутствуют'
      }),
      valueFormatter(params: any) {
        return !_.isNil(params.value)
          ? params.value
            ? moodleTranslation.true
            : moodleTranslation.false
          : 'Неизвестно'
      }
    },
    {
      headerName: 'Роли',
      field: 'roles',
      colId: 'roles',
      width: 150,
      sortable: false,
      cellRenderer: RoleRenderer,
      filter: 'SetFilter',
      hide: !this.isDisplayRole,
      filterParams: {
        filterKeys: Object.keys(rolesTranslation),
        keyFormatter(key: string) {
          return rolesTranslation[key]
        }
      },
      valueFormatter(params: any) {
        return params.value ? params.value.join(', ') : null
      }
    },
    {
      headerName: 'Дата рождения',
      field: 'birthday',
      colId: 'birthday',
      filter: 'agDateColumnFilter',
      width: 86,
      suppressSizeToFit: false,
      hide: !this.isDisplayDateBirth,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (_.isEmpty(params.value)) {
          return 'Не указано'
        } else {
          return moment(params.value).format(dateFormat.date.localFormat)
        }
      }
    },
    {
      headerName: 'Телефон',
      field: 'phone',
      colId: 'phone',
      maxWidth: 140,
      suppressSizeToFit: false,
      hide: !this.isDisplayPhone,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (_.isEmpty(params.value)) {
          return 'Не указано'
        } else {
          return params.value
        }
      }
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          ...this.getActionCallbacks()
        },
        hide: !this.actions.length
      })
    }
  ]

  @Watch('rowData')
  private onRowDataChange(newRowData: User[]) {
    this.gridApi?.setRowData(newRowData)
  }

  @Watch('dataSource')
  private onDatasourceChange(dataSource: IDatasource) {
    this.gridApi?.setDatasource(dataSource)
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    ...(this.dataSource
      ? {
          rowModelType: 'infinite'
        }
      : {}),
    pagination: true,
    paginationPageSize: 15,
    onCellClicked: (params: any) => {
      if (params.column.colId !== 'actions') {
        this.$emit('edit', params)
      }
    },
    onCellDoubleClicked: (params: any) => this.$emit('dblclick', params),
    suppressSizeToFit: true,
    rowBuffer: 100,
    onGridReady: this.onGridReady,
    components: {
      RoleRenderer,
      AgGridFactory
    },
    frameworkComponents: {
      SetFilter,
      ActionRenderer
    }
  }

  private get columnDefs() {
    return this.defaultDefs
  }

  private onGridReady({ api }: { api: any }) {
    if (this.dataSource) {
      api.setDatasource(this.dataSource as IDatasource)
    } else if (this.rowData) {
      api.setRowData(this.rowData)
    }
    this.gridApi = api
  }

  private getActionCallbacks() {
    const callbacks: { [k: string]: (p: ICellRendererParams) => void } = {}
    for (const action of this.actions ?? []) {
      const actionName = `on${_.upperFirst(action)}`
      callbacks[actionName] = (params: ICellRendererParams) => {
        this.$emit(action, params)
      }
    }
    return callbacks
  }

  public reDrawTable() {
    if (this.dataSource) {
      this.gridApi!.setDatasource(this.dataSource)
    } else if (this.rowData) {
      this.gridApi!.setRowData(this.rowData)
    }
  }
}
