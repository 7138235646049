import {
  Actions,
  createMapper,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module'
import { Vue } from 'vue-property-decorator'
import NotificationsAPI from '@/api/requests/notifications'
import _ from 'lodash'
import {
  LIMIT_PAGINATION_LIST,
  notificationTypes
} from '@/config/types/notificationTypes'

const LIMIT = LIMIT_PAGINATION_LIST

export interface Notification {
  id: number
  text: string
  isRead: boolean
  type: string
  userId: number
  createdAt: string
  updatedAt: string
}

class NotificationsState {
  notifications: { [k: string]: Notification[] } = {}
  currentOffset: { [k: string]: number } = {}

  notificationsForBell: Notification[] = []
  unreadCount = 0

  reloadNotification: string | null = ''
}

class NotificationsGetters extends Getters<NotificationsState> {
  get bellNotifications() {
    return _.sortBy(this.state.notificationsForBell, 'createdAt').reverse()
  }
  get unreadCount() {
    return this.state.unreadCount
  }

  get notifications() {
    return this.state.notifications
  }
}

class NotificationsMutations extends Mutations<NotificationsState> {
  setNotifications(notifications: Notification[], type: notificationTypes) {
    Vue.set(this.state.notifications, type, notifications)
  }

  clearNotification() {
    Vue.set(this.state, 'notifications', {})
    Vue.set(this.state, 'currentOffset', {})
  }

  pushNotification({
    notification,
    type
  }: {
    notification: Notification
    type: notificationTypes
  }) {
    if (
      this.state.notifications[type] &&
      this.state.notifications[type].length
    ) {
      this.state.notifications[type].push(notification)
    } else {
      Vue.set(this.state.notifications, type, [notification])
    }
  }

  pushNotifications({
    notifications,
    type
  }: {
    notifications: Notification[]
    type: notificationTypes
  }) {
    if (
      this.state.notifications[type] &&
      this.state.notifications[type].length
    ) {
      Vue.set(
        this.state.notifications,
        type,
        this.state.notifications[type].concat(notifications)
      )
    } else {
      Vue.set(this.state.notifications, type, notifications)
    }
  }

  setCurrentOffset({
    offset,
    type
  }: {
    offset: number
    type: notificationTypes
  }) {
    Vue.set(this.state.currentOffset, type, offset)
  }

  setBellNotifications(notifications: Notification[]) {
    this.state.notificationsForBell = notifications
  }

  setUnreadCount(count: number) {
    this.state.unreadCount = count
  }

  setReloadNotification(not: string | null) {
    this.state.reloadNotification = not
  }
}

class NotificationsActions extends Actions<
  NotificationsState,
  NotificationsGetters,
  NotificationsMutations,
  NotificationsActions
> {
  async fetchBellNotifications() {
    const response = await NotificationsAPI.getUserNotifications(5)
    this.mutations.setBellNotifications(response.data.notifications)
    this.mutations.setUnreadCount(response.data.count)
  }

  async fetchNotifications({
    offset = 0,
    limit = LIMIT,
    type
  }: {
    offset: number | string
    limit?: number
    type?: notificationTypes
  }) {
    if (offset === 0) {
      this.mutations.clearNotification()
    }

    if (type) {
      if (offset === 'next') {
        const response = await NotificationsAPI.getNotificationWithPagination(
          limit,
          this.state.currentOffset[type] + limit,
          type
        )
        this.mutations.pushNotifications({
          notifications: response.data.notifications,
          type: type
        })
        this.mutations.setCurrentOffset({
          offset: this.state.currentOffset[type] + limit,
          type: type
        })
      } else if (_.isNumber(offset)) {
        const response = await NotificationsAPI.getNotificationWithPagination(
          limit,
          offset,
          type
        )
        if (type) {
          this.mutations.pushNotifications({
            notifications: response.data.notifications,
            type: type
          })
          this.mutations.setCurrentOffset({ offset: offset, type: type })
        }
      }
    }
  }
}

export const notifications = new Module({
  state: NotificationsState,
  getters: NotificationsGetters,
  mutations: NotificationsMutations,
  actions: NotificationsActions
})

export const notificationsMapper = createMapper(notifications)
