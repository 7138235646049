









import { Component, Mixins } from 'vue-property-decorator'
import _ from 'lodash'

import { abstractField, GenField } from 'vue-form-generator'
import { schemaAttributes } from '@/config/fields/schema'
import { Datetime } from 'vue-datetime'

@Component({ components: { datetime: Datetime } })
export default class DatetimePickerField extends Mixins(abstractField) {
  schema!: GenField

  public get attrs() {
    return {
      'input-class': 'form-control datepicker-class w-100',
      type: this.schema.inputType ?? 'datetime',
      phrases: this.schema.pharases ?? { ok: 'ОК', cancel: 'Отмена' },
      ..._.omit(this.schema, [...schemaAttributes, 'styleClasses'])
    }
  }
}
