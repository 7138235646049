import Vue from 'vue'
import Vuex from 'vuex'

import { Module, createStore } from 'vuex-smart-module'

// Modules
import { mobileWidth } from '@/store/modules/mobileWidth'
import { user } from '@/store/modules/user'
import { toast } from '@/store/modules/toast'
import { organizations } from '@/store/modules/dicts'
import { navBarTitle } from './modules/navbar'
import { notifications } from '@/store/modules/notifications'
import { loading } from './modules/loading'
import { logger } from './modules/logger'

Vue.use(Vuex)

const root = new Module({
  modules: {
    mobileWidth,
    user,
    toast,
    navBarTitle,
    organizations,
    notifications,
    loading,
    logger
  }
})

export default createStore(root)
