import {
  Getters,
  Mutations,
  Module,
  createMapper,
  Actions
} from 'vuex-smart-module'
import _ from 'lodash'

import { Vue } from 'vue-property-decorator'
import { roles } from '@/config/types/roles'
import UserAPI from '@/api/requests/user'

class UserState {
  user: any = null
}

class UserGetters extends Getters<UserState> {
  get isAuthenticated() {
    return !_.isNil(this.state.user)
  }

  get currentUser() {
    return this.state.user
  }

  get userRoles() {
    return this.state.user?.roles ?? []
  }

  get userOrganizations() {
    return this.state.user?.organizations ?? []
  }

  get userEmailNotif() {
    return this.state.user?.emailNotif ?? ''
  }

  get userNotif() {
    return this.state.user?.getNotif
  }

  get userEvents() {
    return this.state.user?.getEvents
  }

  get userNews() {
    return this.state.user?.getNews
  }

  get isAdmin() {
    return this.getters.userRoles.includes(roles.admin)
  }
  get isStudent() {
    return this.getters.userRoles.includes(roles.student)
  }
  get isTeacher() {
    return this.getters.userRoles.includes(roles.teacher)
  }
  get isCurator() {
    return this.getters.userRoles.includes(roles.curator)
  }
  get isContentManager() {
    return this.getters.userRoles.includes(roles.contentmanager)
  }
  get isDeveloper() {
    return this.getters.userRoles.includes(roles.developer)
  }

  get initials() {
    return this.state.user?.initials ?? ''
  }

  get fullName() {
    return this.state.user?.midname
      ? `${this.state.user?.surname} ${this.state.user?.name} ${this.state.user?.midname}`
      : `${this.state.user?.surname} ${this.state.user?.name}`
  }

  get phone() {
    return this.state.user?.phone ?? ''
  }
  get email() {
    return this.state.user?.email ?? ''
  }
  get birthday() {
    return this.state.user?.birthday ?? ''
  }
  get organization() {
    return this.state.user?.organizations ?? []
  }
  get classLeti() {
    return this.state.user?.class ?? ''
  }
  get portfolio() {
    return this.state.user?.portfolio ?? ''
  }
  get notificationTypes() {
    return this.state.user?.notificationTypes || []
  }

  get isFakeAuth() {
    return !!this.state.user?.actualUser
  }
}

class UserMutations extends Mutations<UserState> {
  setUser(user: any) {
    Vue.set(this.state, 'user', user)
  }
}

class UserActions extends Actions<
  UserState,
  UserGetters,
  UserMutations,
  UserActions
> {
  async fetchCurrentUser(reload = false) {
    const user = await UserAPI.getCurrentUser(reload)
    this.mutations.setUser(user.data.user)
  }
}

export const user = new Module({
  state: UserState,
  getters: UserGetters,
  mutations: UserMutations,
  actions: UserActions
})

export const userMapper = createMapper(user)
