import { defaultProfile } from '@/config/default/defaultImages'
import { roles } from '@/config/types/roles'

export default class UserFactory {
  public static emptyUser() {
    return {
      id: 1,
      initials: '',
      email: '',
      name: '',
      surname: '',
      midname: null as string | null,
      birthday: '',
      class: null as null | number,
      moodleRights: false,
      roles: [] as roles[],
      organizations: [] as any[]
    }
  }

  public static emptyRegistrationUserInfo() {
    return {
      userId: 0,
      surname: '',
      name: '',
      midname: '',
      mail: '',
      phone: '',
      birthday: '',
      password: '',
      activationCode: '',
      samePasswordForConfirm: '',
      agreement: ''
    }
  }

  public static emptyUserProfile() {
    return {
      id: 1,
      email: '',
      name: '',
      surname: '',
      midname: '',
      birthday: '',
      class: null as null | number,
      roles: [] as roles[],
      organizations: [] as any[],
      avatarUrl: defaultProfile,
      phone: '',
      about: '',
      getNotif: false,
      getNews: false,
      getEvents: false,
      vkId: null,
      emailNotif: false,
      notificationTypes: [] as any
    }
  }

  public static emptyClassModel() {
    return {
      class: null as null | number
    }
  }

  public static emptyInviteUserModel(): {
    organizations: any[]
    emails?: string[] | null
    emailString?: string
  } {
    return {
      organizations: [] as any[],
      emails: null as string[] | null,
      emailString: ''
    }
  }

  public static emptyTeacherOrganizations() {
    return {
      organizations: [] as any[]
    }
  }

  public static emptyPassword() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  }

  public static emptyMail() {
    return {
      newMail: '',
      currentPassword: ''
    }
  }
}
