import moment from 'moment'
import dateFormat from '@/config/default/dateFormat'
import { baseURL } from '@/api/requests/httpAxios'
import { imagePreview } from '@/config/default/defaultImages'
import { contentStatus, contentType } from '@/config/content/contentStatus'
import ContentFactory from '@/factories/content'
import _ from 'lodash'

export class ContentAdapter {
  public static changeDateFormat(type: string, date: any) {
    if (type === contentType.event) {
      return ContentAdapter.castDateToTime(date)
    } else if (type === contentType.news) {
      return ContentAdapter.castDateToLocal(date)
    }
    return date
  }

  public static changeDateFormatToDisplay(data: any) {
    return moment(data ?? new Date()).format(dateFormat.date.inputFormat)
  }

  public static castDateToLocal(date: Date) {
    return moment(date ?? new Date()).format(dateFormat.date.localFormat)
  }
  public static castDateToTime(date: Date) {
    return moment(date ?? new Date()).format(dateFormat.date.dateTime)
  }

  public static changeEventsDateFormatToServer(date: Date | string) {
    return moment(date ?? new Date()).format(dateFormat.date.serverFormat)
  }

  public static changeNewsDateFormatToServer(date: Date | string) {
    return moment(date, dateFormat.date.localFormat).format()
  }

  public static adaptAvatarUrl(avatarUrl: string | null) {
    if (!avatarUrl) {
      return imagePreview
    }
    return avatarUrl.startsWith('http') ? avatarUrl : baseURL + avatarUrl
  }

  public static async formatContentData(content: any, status: contentStatus) {
    const formData = new FormData()

    if (ContentFactory.emptyOrganization().avatarUrl === content.avatarUrl) {
      content.avatarUrl = null
    }
    if (!_.isNil(content.avatarUrl)) {
      const avatarBlob = await (await fetch(content.avatarUrl)).blob()
      const avatarFile = new File([avatarBlob], 'avatar.png', {
        type: 'image/png'
      })
      formData.append('avatar', avatarFile)
    }
    formData.append('title', content.title)
    formData.append('type', content.type)
    formData.append('status', status)
    if (!_.isNil(content.eventDate)) {
      formData.append('eventDate', content.eventDate)
    }
    if (!_.isNil(content.shortDescription)) {
      formData.append('shortDescription', content.shortDescription)
    }
    if (!_.isNil(content.fullDescription)) {
      formData.append('fullDescription', content.fullDescription)
    }
    if (!_.isNil(content.votingSettings)) {
      formData.append('votingSettings', JSON.stringify(content.votingSettings))
    }
    if (!_.isNil(content.organizations)) {
      formData.append('organizations', JSON.stringify(content.organizations))
    }
    return formData
  }
}
