


































import { Component, Vue } from 'vue-property-decorator'
import { IHeaderParams } from 'ag-grid-community'

@Component
export default class HeaderRenderer extends Vue {
  params!: IHeaderParams

  private sortStatus: string | null = null
  private activeFilter = false

  private get sortIcon() {
    if (!this.sortStatus) {
      return 'sort'
    } else {
      return this.sortStatus === 'asc'
        ? 'sort-amount-down-alt'
        : 'sort-amount-up'
    }
  }

  private get isEditable() {
    return this.params.column.getColDef().editable
  }
  private onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sortStatus = 'asc'
    } else if (this.params.column.isSortDescending()) {
      this.sortStatus = 'desc'
    } else {
      this.sortStatus = null
    }
  }

  private onSortRequested(order: string, shift: boolean) {
    this.sortStatus = order || null
    this.params.setSort(order, shift)
  }

  private onFilterChanged() {
    this.activeFilter = this.params.column.isFilterActive()
  }

  private updateFilter() {
    this.params.showColumnMenu(this.$refs['header-filter'] as HTMLElement)
  }

  private updateSortMode(event: any) {
    if (!this.sortStatus) {
      this.onSortRequested('asc', event.shiftKey)
    } else if (this.sortStatus === 'asc') {
      this.onSortRequested('desc', event.shiftKey)
    } else {
      this.onSortRequested('', event.shiftKey)
    }
  }

  private created() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged)
    this.params.column.addEventListener('filterChanged', this.onFilterChanged)
    this.onSortChanged()
  }
}
