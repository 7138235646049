import { AxiosPromise } from 'axios'
import { baseURL, clientBase, http, prefixURL } from '@/api/requests/httpAxios'
import { User } from 'interfaces/user'
import { UserAdapter } from '@/api/adapters/user'
import _ from 'lodash'

export default class UserAPI {
  public static checkEmailForChangePassword(email: string): AxiosPromise {
    return http.put('/auth/password-change/set-code', { email })
  }

  public static checkActivationCode(code: string): AxiosPromise {
    return http.get(`/auth/signup/${code}`)
  }

  public static registration(userObject: any): AxiosPromise {
    return http.put('/auth/signup', userObject)
  }

  public static getCurrentUser(
    reload = false
  ): AxiosPromise<{ user: User | null }> {
    const params = new URLSearchParams()
    if (reload) {
      params.append('reload', 'true')
    }
    return http.get('/auth/current-user', { params })
  }

  public static async editUser(data: any) {
    return http.put('/users', await UserAdapter.formatUserData(data), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public static login(login: string, password: string): AxiosPromise {
    return http.post('/auth/login', { login, password })
  }

  public static loginWithLk() {
    const isProduction = process.env.NODE_ENV === 'production'

    if (isProduction) {
      window.location.href = `${baseURL}/auth`
    } else {
      window.location.href = `${baseURL}/auth/dev?userId=1`
    }
  }

  public static loginWithPartnerLk(redirect?: string) {
    window.location.href = `${baseURL}/auth/partner${
      redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''
    }`
  }

  public static loginRedirect(roles: string[], redirect?: any) {
    const params = new URLSearchParams()

    if (roles && roles.length) {
      for (const role of roles) {
        params.append('roles', role)
      }
    } else {
      params.append('roles', '[]')
    }

    if (!redirect) {
      window.location.href = clientBase + '/'
      return
    }

    if (redirect === '/auth/dev?') {
      params.append('userId', '1')
      window.location.href = `${baseURL}${redirect}${params.toString()}`
      return
    }

    if (redirect && _.isString(redirect)) {
      window.location.href = `${baseURL}/${redirect.replace(
        prefixURL + '/',
        ''
      )}`
      return
    }
  }

  public static logout(): AxiosPromise {
    return http.get('/auth/logout')
  }

  public static changeUser(userId: number) {
    window.location.href = `${baseURL}/auth/dev?userId=${userId}`
  }

  public static getProfile(userId: number): AxiosPromise {
    return http.get(`/users/${userId}`)
  }

  public static passwordRecovery(object: any): AxiosPromise {
    return http.put('/auth/password-change', object)
  }

  public static changePassword(object: any): AxiosPromise {
    return http.put('/users/password-change', object)
  }

  public static changeMail(object: any): AxiosPromise {
    return http.put('/users/mail-change', object)
  }

  public static emailConfirmation(object: any): AxiosPromise {
    return http.put('/users/mail-confirmation', object)
  }
}
