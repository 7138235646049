











































































































import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

interface Params extends ICellRendererParams {
  [key: string]: any
}

@Component
export default class AgGridField extends Vue {
  params!: Params
  noActions = false
  noConfirm = false

  private created() {
    if (this.params.data) {
      if (this.params.data._noActions) {
        this.noActions = true
      }
      if (this.params.data._noConfirm) {
        this.noConfirm = true
      }
    }
  }

  private checkType(type: string) {
    return this.params.getActionByType
      ? this.params.getActionByType(this.params).includes(type)
      : true
  }

  private onDelete() {
    this.params.onDelete(this.params)
  }

  private onEdit() {
    this.params.onEdit(this.params)
  }

  private onInfo() {
    this.params.onInfo(this.params)
  }

  private onLink() {
    this.params.onLink(this.params)
  }

  private onDownload() {
    this.params.onDownload(this.params)
  }

  private onConfirm() {
    this.params.onConfirm(this.params)
  }

  private onReset() {
    this.params.onReset(this.params)
  }

  private onProfile() {
    this.params.onProfile(this.params)
  }

  private onExcel() {
    this.params.onExcel(this.params)
  }

  private onStatistics() {
    this.params.onStatistics(this.params)
  }

  private onArrowUp() {
    this.params.onArrowUp(this.params)
  }

  private onArrowDown() {
    this.params.onArrowDown(this.params)
  }
}
