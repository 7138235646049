import axios, { AxiosInstance } from 'axios'
import Config from '@/config/default/config'

export const prefixURL = '/etuclasses/api'

export const baseURL =
  process.env.NODE_ENV === 'production'
    ? prefixURL
    : `http://localhost:3005${prefixURL}`

export const clientBase =
  process.env.NODE_ENV === 'production'
    ? '/etuclasses'
    : 'http://localhost:8080'

export const socketURL =
  process.env.NODE_ENV === 'production' ? '' : 'localhost:3005'

export const clientAbsolutePath = window?.location?.href?.includes('dev')
  ? 'https://dev-class.etu.ru'
  : 'https://class.etu.ru'

export const moodleURL =
  process.env.NODE_ENV === 'production'
    ? clientAbsolutePath + '/moodle'
    : 'http://localhost:8888/moodle311/'

export const http: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true
})

http.interceptors.request.use(config => {
  if (!Config.urlsWithoutInterceptors.includes(config.url!)) {
    const store = require('@/store/index').default
    store.commit('loading/enqueue', null, { root: true })
  }
  return config
})

http.interceptors.response.use(
  response => {
    if (!Config.urlsWithoutInterceptors.includes(response.config.url!)) {
      const store = require('@/store/index').default
      store.commit('loading/dequeue', null, { root: true })
    }
    return response
  },
  error => {
    if (!Config.urlsWithoutInterceptors.includes(error.config.url!)) {
      const store = require('@/store/index').default
      store.commit('loading/dequeue', null, { root: true })
      let data: any
      if (error.response.data.constructor === ArrayBuffer) {
        try {
          data = JSON.parse(new TextDecoder().decode(error.response.data))
        } catch {
          data = {}
        }
      } else {
        data = error.response.data
      }
      console.error(error.response)
      store.dispatch('toast/pushToast', {
        error: true,
        title: 'Ошибка',
        message: data?.message || 'Ошибка сервера'
      })
      if (store.getters['user/isAuthenticated']) {
        store.commit('logger/addLog', {
          userId: 1,
          severity: 'error',
          message: 'ERROR',
          page: window.location.pathname,
          error: {
            type: `axioserror${
              error.response?.status ? ` ${error.response?.status}` : ''
            }`,
            message: data?.message ?? '',
            stack: data?.stack ?? ''
          },
          timestamp: Date.now()
        })
        store.dispatch('logger/sendLogs')
      }
    }
    throw error
  }
)

http.interceptors.response.use(
  response => response,
  error => {
    if (!Config.urlsWithoutInterceptors.includes(error.config.url!)) {
      if (error.response.data?.isLoggedIn === false) {
        const store = require('@/store/index').default
        store.commit('user/setBadLogin', true, { root: true })
      }
    }
    throw error
  }
)
