export default {
  urlsWithoutInterceptors: ['/logs/client'],
  systemTitle: {
    fullName: 'ЛЭТИ-классы',
    shortName: 'ЛЭТИ'
  },
  routing: {
    homePage: {
      path: '/main-page',
      name: 'О проекте'
    },
    authPage: {
      path: '/auth',
      name: 'Авторизация в системе'
    }
  },
  mobileWidth: 700,
  tabletWidth: 992,
  defaultWidth: 1000
}
