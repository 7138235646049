export enum roles {
  'admin' = 'admin', // Администратор
  'contentmanager' = 'contentmanager', // Контент-менеджер
  'curator' = 'curator', // Куратор
  'teacher' = 'teacher', //Классный-руководитель
  'student' = 'student', // Ученик
  'studentETU' = 'studentETU', // Студент
  'partner' = 'partner', // Партнер
  'developer' = 'developer' // Разработчик
}

export const rolesTranslation: { [k: string]: string } = {
  [roles.admin]: 'Администратор',
  [roles.contentmanager]: 'Контент-менеджер',
  [roles.curator]: 'Куратор',
  [roles.teacher]: 'Классный руководитель',
  [roles.student]: 'Ученик',
  [roles.studentETU]: 'Студент',
  [roles.partner]: 'Партнер',
  [roles.developer]: 'Разработчик'
}

export const rolesBadges: { [k: string]: string } = {
  [roles.admin]: 'Адм',
  [roles.contentmanager]: 'КМ',
  [roles.curator]: 'Кур',
  [roles.teacher]: 'КР',
  [roles.student]: 'Уч',
  [roles.studentETU]: 'Ст',
  [roles.partner]: 'П',
  [roles.developer]: 'Dev'
}
