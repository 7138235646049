






































import { Vue, Component, Watch } from 'vue-property-decorator'
import { RouteConfig, RouteConfigSingleView } from 'vue-router/types/router'
import { routes } from '@/router'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { userMapper } from '@/store/modules/user'
import { rolesTranslation } from '@/config/types/roles'
import Config from '@/config/default/config'
import { defaultProfile } from '@/config/default/defaultImages'
import UserFactory from '@/factories/user'
import { UserAdapter } from '@/api/adapters/user'
import FakeUserAPI from '../admin/fake-auth/helpers/requests'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser', 'userRoles', 'isFakeAuth'])
  },
  methods: {
    ...userMapper.mapActions(['fetchCurrentUser'])
  }
})
@Component
export default class Sidebar extends Mappers {
  @Watch('currentUser')
  private onCurrentUserChanged() {
    this.changeProfileUser()
  }

  private treeSidebar: { [k: string]: RouteConfigSingleView[] } = {}
  private title = Config.systemTitle.fullName
  private defaultProfile_ = defaultProfile
  private userInfo = UserFactory.emptyUserProfile()

  private created() {
    this.userInfo = UserAdapter.getFieldsUser(this.currentUser)
    const routerPages: RouteConfig[] | undefined = routes[0].children
    const abilities = PermissionManager.pages()
    this.treeSidebar = {}
    if (routerPages) {
      for (const route of routerPages) {
        if (
          abilities.can(permissions.viewPage, route.name) &&
          route.meta.isDisplayInSidebar
        ) {
          const group = route.meta.group

          if (group in this.treeSidebar) {
            this.treeSidebar[group].push(route)
          } else {
            this.treeSidebar[group] = [route]
          }
        }
      }
    }
  }
  private getRoles() {
    return this.userRoles?.map((r: string) => rolesTranslation[r]).join(', ')
  }
  private changeProfileUser() {
    this.userInfo = UserAdapter.getFieldsUser(this.currentUser)
  }

  private onLogout() {
    FakeUserAPI.fakeUserLogout()
  }
}
