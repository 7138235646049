





























































import { Vue, Component } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import NotificationBell from 'vue-notification-bell'

import NotificationsModal from '@/components/common/modal/NotificationsModal.vue'

import { userMapper } from '@/store/modules/user'
import Config from '@/config/default/config'
import { navBarTitleMapper } from '@/store/modules/navbar'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import UserAPI from '@/api/requests/user'
import { LIMIT_PAGINATION_BELL } from '@/config/types/notificationTypes'
import { notificationsMapper } from '@/store/modules/notifications'
import _ from 'lodash'

const Mappers = Vue.extend({
  methods: {
    ...userMapper.mapActions(['fetchCurrentUser']),
    ...notificationsMapper.mapActions(['fetchBellNotifications'])
  },
  computed: {
    ...notificationsMapper.mapGetters(['unreadCount']),
    ...navBarTitleMapper.mapGetters(['navBarTitle', 'navBarSubTitle']),
    ...userMapper.mapGetters(['isAuthenticated']),
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({ components: { NotificationBell, NotificationsModal } })
export default class Navbar extends Mappers {
  private LIMIT_PAGINATION_BELL_ = LIMIT_PAGINATION_BELL
  private homePage = Config.routing.homePage.path

  private async created() {
    if (this.isAuthenticated) {
      await this.fetchBellNotifications()
    }
  }
  private buildTitle() {
    if (_.isEmpty(this.navBarSubTitle) && this.navBarTitle) {
      return this.navBarTitle
    }
    if (_.isEmpty(this.navBarTitle) && this.navBarSubTitle) {
      return this.navBarSubTitle
    }
    if (this.navBarTitle && this.navBarSubTitle) {
      if (this.isMobile) {
        return this.navBarSubTitle
      } else {
        return `${this.navBarTitle}. ${this.navBarSubTitle}`
      }
    }
  }
  public $refs!: {
    logoutModal: BModal
  }
  private onLogout() {
    this.$refs.logoutModal.show()
  }
  private onLogIn() {
    if (this.$route.path != Config.routing.authPage.path) {
      this.$router.push(Config.routing.authPage.path)
    }
  }
  private async onLogoutOK() {
    await UserAPI.logout()
    await this.fetchCurrentUser()
    this.$router.push(Config.routing.authPage.path)
  }
  private onBellClick() {
    this.$bvModal.show('notificationsModal')
  }
  private onClickEntryExit() {
    this.isAuthenticated ? this.onLogout() : this.onLogIn()
  }
}
