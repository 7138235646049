import { contentStatus, contentType } from '@/config/content/contentStatus'
import { typeOrganization } from '@/config/types/typeOrganization'
import { imagePreview } from '@/config/default/defaultImages'
import { Organization } from 'interfaces/organization'

export default class ContentFactory {
  public static emptyNewsObject() {
    return {
      id: null,
      title: '',
      eventDate: '',
      shortDescription: '',
      fullDescription: '',
      avatarUrl: imagePreview,
      likes: 0,
      dislikes: 0,
      views: 0,
      type: contentType.news,
      status: contentStatus.draft
    }
  }

  public static emptyEventsObject() {
    return {
      id: null,
      title: '',
      shortDescription: '',
      eventDate: '',
      fullDescription: '',
      avatarUrl: imagePreview,
      likes: 0,
      dislikes: 0,
      views: 0,
      type: contentType.event,
      status: contentStatus.draft,
      votingSettings: null,
      voteData: null,
      organizations: [] as Organization[]
    }
  }
  public static contentManagerEventsObject() {
    return {
      id: null,
      title: 'Все',
      shortDescription: '',
      eventDate: '',
      fullDescription: '',
      avatarUrl: imagePreview,
      likes: 0,
      dislikes: 0,
      views: 0,
      type: contentType.event,
      status: contentStatus.draft,
      organizations: [{ id: null, title: 'Все' }]
    }
  }
  public static emptyOrganization() {
    return {
      id: -1,
      title: '',
      shortDescription: '',
      fullDescription: '',
      avatarUrl: imagePreview,
      address: '',
      type: contentType.organization,
      organizationType: typeOrganization.Другое
    }
  }
  public static emptyOrgSelectModel() {
    return {
      organizations: [] as any[]
    }
  }

  public static emptyVote() {
    return {
      settings: {
        fieldsDefault: ['ФИО', 'Почта', 'Класс/Организация']
      }
    }
  }
  public static defaultVote() {
    return {
      ФИО: '',
      Почта: '',
      'Класс/Организация': ''
    }
  }
}
