import Vue from 'vue'
import _ from 'lodash'
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Config from '@/config/default/config'
import store from '@/store'
import { logger } from '@/store/modules/logger'

import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { moodleURL } from '@/api/requests/httpAxios'

Vue.use(VueRouter)

const sendLogs: NavigationGuard = (to, from, next) => {
  if (store.getters['user/isAuthenticated']) {
    const loggerState = logger.context(store)
    loggerState.mutations.addLog({
      userId: !_.isUndefined(store.state.user.user)
        ? store.state.user.user.id
        : null,
      severity: 'info',
      message: 'NEW PAGE',
      page: to.fullPath,
      timestamp: Date.now()
    })
  }
  next()
}

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: Config.routing.homePage.path,
    children: [
      // ======================= Start Auth =======================
      {
        path: '/auth/user',
        name: 'AuthUser',
        meta: {
          icon: null,
          isDisplayInSidebar: false,
          group: Config.systemTitle.fullName,
          displayName: Config.routing.authPage.name
        },
        beforeEnter: sendLogs,
        component: () =>
          import(/* webpackChunkName: "AuthUser" */ '@/views/auth/AuthUser.vue')
      },
      {
        path: Config.routing.authPage.path,
        name: 'Auth',
        meta: {
          icon: null,
          isDisplayInSidebar: false,
          group: Config.systemTitle.fullName,
          displayName: Config.routing.authPage.name
        },
        beforeEnter: sendLogs,
        component: () =>
          import(/* webpackChunkName: "Auth" */ '@/views/auth/Auth.vue')
      },
      {
        path: '/registration',
        name: 'Registration',
        meta: {
          displayName: 'Регистрация',
          group: Config.systemTitle.fullName,
          icon: ['fa', 'address-card'],
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/auth/Registration.vue'
          )
      },
      {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        meta: {
          displayName: 'Восстановление пароля',
          group: Config.systemTitle.fullName,
          icon: ['fas', 'key'],
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "PasswordRecovery" */ '@/views/auth/PasswordRecovery.vue'
          )
      },
      {
        path: '/user/email-confirmation',
        name: 'EmailConfirmation',
        meta: {
          icon: ['fa', 'envelope'],
          isDisplayInSidebar: false,
          group: Config.systemTitle.fullName,
          displayName: 'Изменение почты'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "EmailConfirmation" */ '@/views/auth/EmailConfirmation.vue'
          )
      },
      // ======================= End Auth =======================

      // ======================= Start Profile =======================
      {
        path: '/profile',
        name: 'Profile',
        meta: {
          icon: ['fa', 'user'],
          isDisplayInSidebar: true,
          group: 'Основное',
          displayName: 'Профиль'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ '@/views/profile/Profile.vue'
          )
      },
      {
        path: '/portfolio',
        name: 'PortfolioStudent',
        meta: {
          icon: ['fa', 'book'],
          isDisplayInSidebar: false,
          group: 'Основное',
          displayName: 'Портфолио'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Portfolio" */ '@/views/profile/Portfolio.vue'
          )
      },
      {
        path: '/profile/:id',
        name: 'UserProfile',
        meta: {
          icon: ['fa', 'user'],
          isDisplayInSidebar: false,
          group: 'Основное',
          displayName: 'Профиль'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "UserProfile" */ '@/views/profile/Profile.vue'
          )
      },
      {
        path: '/notifications',
        name: 'Notifications',
        meta: {
          icon: ['fas', 'bell'],
          isDisplayInSidebar: true,
          group: 'Основное',
          displayName: 'Уведомления'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Notifications" */ '@/views/profile/Notifications.vue'
          )
      },
      {
        path: '/feed',
        name: 'Feed',
        meta: {
          displayName: 'События и Новости',
          group: 'Основное',
          icon: ['fa', 'newspaper'],
          isDisplayInSidebar: true
        },
        beforeEnter: sendLogs,
        component: () =>
          import(/* webpackChunkName: "Feed" */ '@/views/main/Feed.vue')
      },

      // ======================= Start Content =======================
      {
        path: '/news',
        name: 'AllNews',
        meta: {
          displayName: 'Новости',
          group: 'Основное',
          icon: null,
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "AllNews" */ '@/views/content/AllNews.vue'
          )
      },
      {
        path: '/events',
        name: 'AllEvents',
        meta: {
          displayName: 'События',
          group: 'Основное',
          icon: null,
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "AllEvents" */ '@/views/content/AllEvents.vue'
          )
      },
      {
        path: '/news/:id',
        name: 'NewsViewer',
        meta: {
          displayName: 'Новость',
          group: 'Основное',
          icon: null,
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "NewsViewer" */ '@/views/content/NewsViewer.vue'
          )
      },
      {
        path: '/events/:id',
        name: 'EventsViewer',
        meta: {
          displayName: 'Событие',
          group: 'Основное',
          icon: null,
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "EventsViewer" */ '@/views/content/EventsViewer.vue'
          )
      },
      // ======================= End Content =======================

      // ======================= Start Moodle =======================
      {
        path: '/moodle-redirect',
        name: 'Moodle',
        meta: {
          icon: ['fa', 'sign-in-alt'],
          isDisplayInSidebar: true,
          group: 'Moodle',
          displayName: 'Перейти в Moodle'
        },
        beforeEnter: () => {
          window.location.href = moodleURL
        }
      },
      // ======================= End Moodle =======================

      // ======================= Start Projects =======================
      {
        path: '/projects',
        name: 'ProjectsListRoute',
        meta: {
          group: 'Проекты',
          displayName: 'Список проектов',
          icon: ['fa', 'list-ul'],
          isDisplayInSidebar: true
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ProjectsListRoute" */ '@/views/projects/ProjectsListRoute.vue'
          )
      },
      {
        path: '/projects/add',
        name: 'ProjectAddRoute',
        meta: {
          group: 'Проекты',
          displayName: 'Добавление проекта',
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ProjectAddRoute" */ '@/views/projects/ProjectAddRoute.vue'
          )
      },
      {
        path: '/projects/:id',
        name: 'ProjectPageRoute',
        meta: {
          group: 'Проекты',
          displayName: 'Страница проекта',
          isDisplayInSidebar: false
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ProjectPageRoute" */ '@/views/projects/ProjectPageRoute.vue'
          )
      },
      // ======================= End Projects =======================

      // ======================= Start Organizations =======================
      {
        path: '/organizations',
        name: 'ListOrganizationsView',
        meta: {
          icon: ['fa', 'university'],
          isDisplayInSidebar: true,
          group: 'Организации',
          displayName: 'Список организаций'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ListOrganizationsView" */ '@/views/admin/ListOrganizationsView.vue'
          )
      },
      {
        path: '/organizations/:id',
        name: 'OrganizationViewer',
        meta: {
          icon: ['fa', 'cog'],
          isDisplayInSidebar: false,
          group: 'Организации',
          displayName: 'Просмотр организации'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "OrganizationViewer" */ '@/views/content/OrganizationViewer.vue'
          )
      },
      // ======================= End Organizations =======================

      // ======================= Start Management =======================
      {
        path: '/students',
        name: 'StudentsList',
        meta: {
          icon: ['fa', 'list-ul'],
          isDisplayInSidebar: true,
          group: 'Управление',
          displayName: 'Список пользователей'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "StudentsList" */ '@/views/classleader/StudentsList.vue'
          )
      },
      {
        path: '/students-etu',
        name: 'StudentsETUList.vue',
        meta: {
          icon: ['fa', 'list-ul'],
          isDisplayInSidebar: true,
          group: 'Управление',
          displayName: 'Список студентов'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "StudentsETUList" */ '@/views/curator/StudentsETUList.vue'
          )
      },
      // ======================= End Management =======================

      // ======================= Start Admin =======================
      {
        path: '/admin/users',
        name: 'AdminListOfUsers',
        meta: {
          displayName: 'Управление пользователями',
          group: 'Администрирование',
          icon: ['fa', 'list-ul'],
          isDisplayInSidebar: true
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "AdminListOfUsers" */ '@/views/admin/AdminListOfUsers.vue'
          )
      },
      {
        path: '/admin/activity',
        name: 'Activity',
        meta: {
          icon: ['fa', 'tasks'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Журнал активности'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ '@/views/admin/Activity.vue'
          )
      },
      {
        path: '/admin/logs',
        name: 'ServerLogs',
        meta: {
          icon: ['fas', 'satellite-dish'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Логи сервера'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ServerLogs" */ '@/views/admin/ServerLogs.vue'
          )
      },
      {
        path: '/admin/sessions',
        name: 'Sessions',
        meta: {
          icon: ['fas', 'binoculars'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Активные сессии'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "Sessions" */ '@/views/admin/Sessions.vue'
          )
      },
      {
        path: '/admin/build',
        name: 'BuildInfo',
        meta: {
          icon: ['fa', 'cog'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Обслуживание системы'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "BuildInfo" */ '@/views/admin/BuildInfo.vue'
          )
      },

      {
        path: '/about/edit',
        name: 'AboutProjectEditForm',
        meta: {
          icon: ['fas', 'edit'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Редактирование "О проекте"'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "AboutProjectEditForm" */ '@/views/admin/AboutProjectEditForm.vue'
          )
      },
      {
        path: '/admin/fake-auth',
        name: 'FakeAuthRoute',
        meta: {
          icon: ['fa', 'user-secret'],
          isDisplayInSidebar: true,
          group: 'Администрирование',
          displayName: 'Просмотр за другого пользователя'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "FakeAuthRoute" */ '@/views/admin/FakeAuthRoute.vue'
          )
      },
      // ======================= End Admin =======================

      // ======================= Start Common =======================
      {
        path: '/error',
        name: 'ErrorPage',
        meta: {
          icon: null,
          isDisplayInSidebar: false,
          group: 'Ошибка',
          displayName: ''
        },
        beforeEnter: sendLogs,
        component: () =>
          import(/* webpackChunkName: "ErrorPage" */ '@/views/ErrorPage.vue')
      },
      {
        path: '/error-auth',
        name: 'ErrorAuthLkPage',
        meta: {
          icon: null,
          isDisplayInSidebar: false,
          group: 'Ошибка',
          displayName: ''
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ErrorAuthLkPage" */ '@/views/ErrorAuthLkPage.vue'
          )
      },
      {
        path: Config.routing.homePage.path,
        name: 'MainPage',
        meta: {
          icon: ['fa', 'home'],
          isDisplayInSidebar: true,
          group: 'Главная страница',
          displayName: Config.routing.homePage.name
        },
        component: () =>
          import(/* webpackChunkName: "MainPage" */ '@/views/main/MainPage.vue')
      },
      {
        path: '/loading-wiki',
        name: 'Help',
        meta: {
          icon: ['fa', 'question-circle'],
          isDisplayInSidebar: true,
          group: 'Главная страница',
          displayName: 'Справка'
        },
        beforeEnter: () => {
          window.location.href = 'https://class.etu.ru/wiki'
        }
      },

      // ======================= End Common =======================

      // ======================= Start Dev =======================
      {
        path: '/test-roles',
        name: 'ChangeRole',
        meta: {
          icon: ['fas', 'sync'],
          isDisplayInSidebar: true,
          group: 'Dev',
          displayName: 'Смена ролей и прав'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "ChangeRole" */ '@/views/auth/ChangeRoleAuth.vue'
          )
      },
      {
        path: '/development',
        name: 'DevelopmentPage',
        meta: {
          icon: ['fa', 'database'],
          isDisplayInSidebar: true,
          group: 'Dev',
          displayName: 'Примеры компонентов'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "DevelopmentPage" */ '@/views/dev/DevelopmentPage.vue'
          )
      },
      {
        path: '/moodle-test',
        name: 'DevelopmentMoodlePage',
        meta: {
          icon: ['fa', 'brain'],
          isDisplayInSidebar: true,
          group: 'Dev',
          displayName: 'Moodle'
        },
        beforeEnter: sendLogs,
        component: () =>
          import(
            /* webpackChunkName: "DevelopmentMoodlePage" */ '@/views/dev/DevelopmentMoodlePage.vue'
          )
      }
      // ======================= End Dev =======================
    ]
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function findPathByName(name: string) {
  const routerPages: RouteConfig[] | undefined = routes[0].children
  if (routerPages) {
    return routerPages.find(r => r.meta.displayName === name)
  }
}

router.beforeEach((to, from, next) => {
  store.commit(
    'navBarTitle/setTitleFromRoute',
    { width: window?.innerWidth ?? Config.defaultWidth, to: to },
    { root: true }
  )
  const redirectAuthed = findPathByName(Config.routing.homePage.name)
  const redirectNonAuthed = findPathByName(Config.routing.authPage.name)

  const abilities = PermissionManager.pages()
  const pageName: string = to.name ? to.name : ''

  const isAuthed = store.getters['user/isAuthenticated']
  const canViewThisPage = abilities.can(permissions.viewPage, pageName)

  routes[0].redirect = isAuthed ? redirectAuthed!.path : redirectNonAuthed!.path

  if (to.path === routes[0].redirect) {
    return next()
  }
  if (!isAuthed) {
    if (canViewThisPage) {
      return next()
    } else {
      return next(routes[0].redirect)
    }
  }
  if (isAuthed && !canViewThisPage && to.path !== '/error') {
    store.dispatch('toast/pushToast', {
      error: true,
      title: 'Права',
      message:
        `Пользователь не может просматривать данную страницу.<br>` +
        `Выйдите из системы или авторизуйтесь за другого пользователя`
    })
  }

  if (!canViewThisPage) {
    return next('/error')
  }

  next()
})
