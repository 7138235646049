// ONLY FOR INFINITE MODEL !!!
// filterKeys in colDef for filter keys
// keyFormatter in colDef to format key label

import { Component, Vue } from 'vue-property-decorator'
import { IFilterParams, IDoesFilterPassParams } from 'ag-grid-community'
import _ from 'lodash'

interface Params extends IFilterParams {
  [key: string]: any
}

@Component({
  template: `
    <div style="width: 200px;">
      <b-form-checkbox-group v-model="selected" @change="onChecked">
        <div style="padding: 5px;" v-for="key in keys">
          <b-form-checkbox :key="key" :value="key">
            <p style="margin-top: 3px; margin-bottom: 3px;">
              {{ keyFormatter ? keyFormatter(key) : key }}
            </p>
          </b-form-checkbox>
        </div>
      </b-form-checkbox-group>
    </div>
  `
})
export default class ActivityRolesFilter extends Vue {
  private params!: Params
  private keys?: any[]
  private keyFormatter: any

  private selected?: string[] = []

  public isFilterActive() {
    return !_.isEmpty(this.selected)
  }

  public doesFilterPass(params: IDoesFilterPassParams) {
    const value = this.params.valueGetter(params.node)
    if (Array.isArray(value)) {
      return _.intersection(value, this.selected).length
    }
    return this.selected?.includes(value)
  }

  public getModel() {
    if (!_.isEmpty(this.selected)) {
      return {
        type: 'enum',
        filter: this.selected
      }
    }
    return null
  }

  public setModel(model?: string[]) {
    this.selected = model
  }

  created() {
    this.keys = this.params.filterKeys ?? this.params.keys
    this.keyFormatter = this.params.keyFormatter ?? null
  }

  private onChecked() {
    this.$nextTick(() => {
      this.params.filterChangedCallback()
    })
  }
}
