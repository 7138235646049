export enum notificationTypes {
  moodle = 'moodle',
  participationStudentEvents = 'participationStudentEvents',
  changingAuthority = 'changingAuthority'
}

export const notificationTypesTranslation: { [k: string]: string } = {
  [notificationTypes.moodle]: 'Moodle',
  [notificationTypes.participationStudentEvents]: 'Участие в событиях',
  [notificationTypes.changingAuthority]: 'Новые полномочия'
}

export const notificationTypesTabs: { [k: string]: string } = {
  [notificationTypes.changingAuthority]: 'Смена полномочий',
  [notificationTypes.moodle]: 'Moodle',
  [notificationTypes.participationStudentEvents]: 'События'
}

export const LIMIT_PAGINATION_LIST = 10
export const LIMIT_PAGINATION_BELL = 5
export const ID_GROUP_VK = 205808061
