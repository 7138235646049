import _ from 'lodash'

export default class CustomFilter {
  static getFilter(variants: any, allType?: string) {
    const filter: any = {
      filterOptions: [],
      suppressAndOrCondition: true
    }
    filter.filterOptions.push({
      displayKey: 'empty',
      displayName: allType ?? 'Все варианты',
      test: function(): boolean {
        return true
      },
      hideFilterInput: true
    })
    for (const [key, value] of Object.entries(variants)) {
      filter.filterOptions.push({
        displayKey: key,
        displayName: value,
        test: function(filterValue: any, cellValue: string): boolean {
          return (
            cellValue != null &&
            (cellValue === key ||
              cellValue.toString() === key ||
              (_.isArray(cellValue) && cellValue.includes(key)))
          )
        },
        hideFilterInput: true
      })
    }
    return filter
  }
}
