












































import { Component, Vue } from 'vue-property-decorator'
import dateFormat from '@/config/default/dateFormat'
import moment from 'moment'
import { notificationTypesTranslation } from '@/config/types/notificationTypes'
import { notificationsMapper } from '@/store/modules/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapGetters(['bellNotifications'])
  }
})

@Component
export default class NotificationsModal extends Mappers {
  private mapper = notificationTypesTranslation

  private redirectToNotificationPage() {
    this.$bvModal.hide('notificationsModal')
    if (this.$route.path != '/notifications') {
      this.$router.push('/notifications')
    }
  }
  private getFormattedTime(timestamp: string) {
    return timestamp
      ? moment(timestamp)
          .locale('ru')
          .format(dateFormat.date.message)
      : ''
  }
}
