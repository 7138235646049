import UserFactory from '@/factories/user'
import { baseURL } from '@/api/requests/httpAxios'
import _ from 'lodash'
import { ContentAdapter } from '@/api/adapters/content'

export class UserAdapter {
  public static getFieldsUser(currentUser: any) {
    const userInfo = UserFactory.emptyUserProfile()
    const avatarUrl = currentUser.avatarUrl
      ? baseURL + currentUser.avatarUrl
      : userInfo.avatarUrl
    return {
      id: currentUser.id ?? userInfo.id,
      email: currentUser.email ?? userInfo.email,
      name: currentUser.name ?? userInfo.name,
      surname: currentUser.surname ?? userInfo.surname,
      midname: currentUser.midname ?? userInfo.midname,
      phone: currentUser.phone ?? userInfo.phone,
      birthday:
        ContentAdapter.changeDateFormatToDisplay(currentUser.birthday) ??
        ContentAdapter.changeDateFormatToDisplay(userInfo.birthday),
      organizations: currentUser.organizations ?? userInfo.organizations,
      about: currentUser.about ?? userInfo.about,
      avatarUrl: avatarUrl,
      class: currentUser.class ?? userInfo.class,
      roles: currentUser.roles ?? userInfo.roles,
      getNotif: currentUser.getNotif ?? userInfo.getNotif,
      getNews: currentUser.getNews ?? userInfo.getNews,
      getEvents: currentUser.getEvents ?? userInfo.getEvents,
      vkId: currentUser.vkId ?? userInfo.vkId,
      emailNotif: currentUser.emailNotif ?? userInfo.emailNotif,
      notificationTypes: currentUser.type ?? userInfo.notificationTypes
    }
  }

  public static async formatUserData(user: any) {
    const formData = new FormData()

    if (UserFactory.emptyUserProfile().avatarUrl === user.avatarUrl) {
      user.avatarUrl = null
    }

    if (!_.isNil(user.avatarUrl)) {
      const avatarBlob = await (await fetch(user.avatarUrl)).blob()
      const avatarFile = new File([avatarBlob], 'avatar.png', {
        type: 'image/png'
      })
      formData.append('avatar', avatarFile)
    }
    formData.append('name', user.name)
    formData.append('surname', user.surname)
    if (!_.isNil(user.midname)) {
      formData.append('midname', user.midname)
    }
    if (!_.isNil(user.phone)) {
      formData.append('phone', user.phone)
    }
    if (!_.isNil(user.birthday)) {
      formData.append('birthday', user.birthday)
    }
    if (!_.isNil(user.about)) {
      formData.append('about', user.about)
    }
    return formData
  }
}
