export enum contentType {
  'news' = 'news', // Новость
  'event' = 'event', // Событие
  'organization' = 'organization' // Организация
}

export enum contentStatus {
  'all' = 'all', // все
  'publicated' = 'publicated', // опубликовано
  'draft' = 'draft', // черновик
  'my' = 'my' // мои
}

export enum contentStatusButtonVariant {
  'pressed' = 'primary',
  'notPressed' = 'outline-primary'
}

export const contentStatusBadges: { [k: string]: string } = {
  [contentStatus.publicated]: 'primary',
  [contentStatus.draft]: 'danger'
}

export const contentStatusTranslation: { [k: string]: string } = {
  [contentStatus.all]: 'Все',
  [contentStatus.publicated]: 'Опубликовано',
  [contentStatus.draft]: 'Черновик',
  [contentStatus.my]: 'Мои'
}

export const publicationButtonTranslation: { [k: string]: string } = {
  [contentStatus.publicated]: 'Снять с публикации',
  [contentStatus.draft]: 'Опубликовать'
}
