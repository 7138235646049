export const schemaAttributes: string[] = [
  'type',
  'label',
  'model',
  'id',
  'inputName',
  'featured',
  'visbile',
  'required',
  'disabled',
  'multi',
  'default',
  'help',
  'hint',
  'validator',
  'validatorDebounceTime',
  'buttons',
  'attributes',
  'onChanged'
]
